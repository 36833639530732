import {CorporationStatus} from "~/enum";

export default defineNuxtRouteMiddleware(async (to, from) => {
    const nuxtApp = useNuxtApp();

    //If user is not logged in or not seller and trying to access seller completion route redirect to register route
    if ((!nuxtApp.$me.check() || !nuxtApp.$me.isSeller()) && to.name?.includes('register-seller-activity')) {
        let registerPath = await nuxtApp.$localePath('register-seller');
        return await navigateTo(registerPath);
    }

    //If user is logged in and is seller and not complete and trying to access seller register route, redirect to completion route
    if(
        nuxtApp.$me.check()
        && nuxtApp.$me.isSeller()
        && to.name?.includes('register-seller__')
        && !isSellerInfoCompleted(nuxtApp.$me)
    ) {
        let completeInfoPath = await nuxtApp.$localePath('register-seller-activity');
        return await navigateTo(completeInfoPath);
    }

    //If user is logged in and is seller and is complete and trying to access seller completion or register route, redirect to home
    if(
        nuxtApp.$me.check()
        && nuxtApp.$me.isSeller()
        && (
            to.name?.includes('register-seller__')
            || to.name?.includes('register-seller-activity')
        )
        && isSellerInfoCompleted(nuxtApp.$me)
    ) {
        let indexPath = await nuxtApp.$localePath('/');
        return await navigateTo(indexPath);
    }

    //If user is not logged in or is not seller and trying to access other route than register seller route, redirect to home
    if(
        (
            !nuxtApp.$me.check()
            || ! (nuxtApp.$me.isSeller() || nuxtApp.$me.isAdmin())
        )
        && !to.name?.includes('register-seller__')
    ) {
        let indexPath = await nuxtApp.$localePath('/');
        return await navigateTo(indexPath);
    }
})

function isSellerInfoCompleted(me: any){
    const meObject = me.get();
    return meObject.corporation?.status !== CorporationStatus.WAITING_INFO;
}